@import "scss/index";

html {
  height: 100%;
}

.connect-bg-container {
  background-image: url("./assets/images/connect-demo-bg.jpg");
}

.connect-bg-container.demo {
  background-image: url("./assets/images/connect-demo-bg.jpg");
}

html {
  height: 100%;
}

body {
  font-family: "Helvetica Now";
  letter-spacing: 0.4px;
  background-attachment: fixed;
  min-width: 0 !important;
}
